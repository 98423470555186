<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa' && rol != 'admin-sucursal'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container grid-list-xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Departamentos'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        :filters="filters"
                         @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="loading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" v-if="permisos.create">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'admin-sucursal'" xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col v-else xs="12" sm="6" md="6" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Nombre"
                                    v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                ></v-autocomplete>
                              </v-col>
                              <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                ></v-autocomplete>
                              </v-col>
                                <v-col v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'" xs="12" sm="6" md="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                        no-data-text="Datos no disponibles"
                                    ></v-autocomplete>
                                </v-col>
                         </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                    <div class="tblPrincipal ml-1">{{ item.nombre }}</div>
                                    <div class="tblSecundario ml-1">{{ item.empresa.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.encargado }}</div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">{{ item.sucursal.nombre }}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="abrirModal('dias', item)"
                                                >
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton">today</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Dias festivos</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="permisos.delete">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    icon
                                                    fab
                                                    dark
                                                    small
                                                    @click="eliminar(item.id, item.nombre)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template v-if="modalRegistro">
            <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <perfect-scrollbar style="background: #fff">
                    <v-card elevation="0">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                <v-text-field
                                                    v-model="departamento.nombre"
                                                    outlined
                                                    autofocus
                                                    id="primero"
                                                    label="Nombre"
                                                    :error-messages="errors"
                                                    required
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Encargado" rules="required">
                                                <v-text-field
                                                    v-model="departamento.encargado"
                                                    outlined
                                                    label="Encargado"
                                                    :error-messages="errors"
                                                    required
                                                />
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0 d-flex">
                                            <div class="textoCorreos">Ingresa los correos de personas que darán seguimiento a las solicitudes del departamento:</div>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 mr-3 outlined_v_icon">{{'info'}}</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    Ejemplo: jackson.graham@example.com, debra.hold@example.com, michelle.rivera@example.com, geogia.young@example.com
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Emails" rules="required">
                                                <v-textarea
                                                    v-model="departamento.correo"
                                                    outlined
                                                    label="Emails"
                                                    :error-messages="errors"
                                                    required
                                                    rows="3"
                                                    :persistent-placeholder="true"
                                                    placeholder="jackson.graham@example.com, debra.hold@example.com, michelle.rivera@example.com, geogia.young@example.com"
                                                ></v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <template v-if="rol == 'root'">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="cliente" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="clientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Cliente"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-if="rol == 'root' || rol == 'admin'">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="empresa" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="empresas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Empresa"
                                                        persistent-hint
                                                        v-model="empresa_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="sucursal" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="sucursales"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Sucursal"
                                                        persistent-hint
                                                        v-model="sucursal_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <template v-if="rol == 'admin-empresa'">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="sucursal" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="sucursales"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Sucursal"
                                                        persistent-hint
                                                        v-model="sucursal_value"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <v-textarea
                                                outlined
                                                label="Descripción"
                                                v-model="departamento.descripcion"
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>

                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardar()" 
                                    :disabled="invalid" 
                                    :loading="isSaving"
                                    v-if="(permisos.update) || (permisos.create && permisos.update == false && accion == 'add')"
                                >
                                    Guardar
                                </v-btn>

                            </v-card-actions>

                            <div class="px-15 pb-10" v-if="permisos.update == false && accion == 'update'">
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                >
                                    El usuario no tiene permiso para editar el registro.
                                </v-alert>
                            </div>
                        </ValidationObserver>
                    </v-card>
                </perfect-scrollbar>
            </v-dialog>
        </template>
        <template v-if="modalDias">
            <v-layout row justify-center>
                <v-dialog v-model="dialog" scrollable persistent max-width="400px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0">
                            <div>
                                <v-btn
                                    @click="cerrarModal()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar float-right mr-3 mt-3"
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            </div>

                            <v-card-text class="card_texto">
                                <v-row dense class="pa-0 ma-0">
                                    <v-col
                                        cols="12"
                                        class="my-2 mx-0 py-2 pl-0 pr-2"
                                        style="margin-bottom: -6% !important"
                                    >
                                        <v-list>
                                            <v-list-item two-line class="px-0">
                                                <v-list-item-content style="margin-top: 20px">
                                                    <p class="titleModalDiasFestivos">Días festivos {{ anio_Actual }}</p>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>

                                    <div id="loadingModal" v-show="!finaliza_carga">
                                        <div id="loading ">
                                            <v-progress-circular
                                                indeterminate
                                                :size="70"
                                                :width="7"
                                                color="blue"
                                                class="mb-9 pb-9"
                                            ></v-progress-circular>
                                        </div>
                                    </div>

                                    <template
                                        v-if="dias_festivos.length == 0 &&  finaliza_carga"
                                    >
                                        <v-col cols="12" class="descripcion px-0">
                                            <div style="display: flex; width:100%; justify-content: flex-start;" class="text-center align-center">
                                                <hr class="pipeDiasFestivos"/>
                                                <h1 class="nombreSinDias">Aún no cuenta con días festivos</h1>
                                            </div>
                                            
                                        </v-col>
                                        <v-col cols="12" class="divisionDias" ></v-col>
                                    </template>

                                    <template
                                        v-for="(dia, index) in dias_festivos"
                                        id="DiasFestivos"
                                    >
                                        <v-col cols="12" class="descripcion px-0" :key="index">
                                            <div style="display: flex; width:100%; justify-content: flex-start;">
                                                <hr class="pipeDiasFestivos"/>
                                                <h1 class="nombreDia">{{ dia.nombre }}</h1>
                                            </div>
                                            <!-- <div class="pipeDiasFestivos"></div>
                                            <h1 class="nombreDia">{{ dia.nombre }}</h1> -->
                                            <h1 class="fechas" style="margin-bottom: 15px">
                                                {{ dia.fecha_formateada }}
                                            </h1>
                                            <div class="descripcion">{{ dia.descripcion }}</div>
                                        </v-col>
                                        <v-col cols="12" class="divisionDias" :key="'A'+index"></v-col>
                                    </template>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </v-layout>
        </template>
    </div>
</template>
<script>
import Datatable from "@/components/g-datatable/Datatable.vue";
import queries from '@/queries/Departamentos';
import departamentoApi from "../api/departamentos";
import clienteAPi from "../api/clientes";
import empresaApi from "../api/empresas";
import sucursalApi from "../api/sucursales";
import Notify from "@/plugins/notify";
import PerfectScrollbar from "perfect-scrollbar";

export default {
    components:{
        "data-table": Datatable
    },
    name: "Departamentos",
    data() {
        return {
            columns: [
                { label: "Nombre del departamento", name: "nombre", filterable: true },
                { label: "Encargado", name: "encargado", filterable: true },
                { label: "Sucursal", name: "sucursal_nombre", filterable: false },
                { label: "Opciones", name: "opciones", align: "center", filterable: false },
            ],
            tableData       : {},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            //Variables para departamento
            departamento: {
                id: 0,
                nombre: "",
                encargado: "",
                correo: "",
                descripcion: "",
                sucursal_id: null,
                empresa_id: null,
                cliente_id: null,
            },
            isLoading : false,
            loading : true,
            //variables para el modal
            dialog          : false,
            tituloModal     : null,
            imagen          : "",
            //para guardar
            isSaving        : false,
            //Dias Festivos
            modalDias: false,
            modalRegistro: false,
            dias_festivos: [],
            dias: false,
            //cliente , empresas y sucursales
            filters : {},
            clientes: [],
            empresas: [],
            sucursales: [],
            nombre : null,
            cliente_value: null,
            empresa_value: null,
            sucursal_value: null,
            rol: null,
            anio_Actual : null,
            isLoadingModal : false,

            permisos :{
                create: false,
                update: false,
                delete: false,
            }

        }
    },
    watch: {
        cliente_value: function(val) {
            if(val != null){
                this.empresa_value = null;
                this.sucursal_value = null;
                this.empresas = [];
                this.sucursales = [];
                this.cambiaCliente(val);
            }
        },
        empresa_value: function(val) {
            if(val != null){
                this.cambiaEmpresa(val);
            }
        },
    },
    methods:{
        /**
         * @method listar Método para listar en tabla
         * @description Este metodo sirve para listar los departamentos en la tabla segun sea el rol
         */
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            this.filters = {AND:[]};
            this.getClientes();
            if (this.rol == "admin") {
                this.departamento.cliente_id = this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
            } else if (this.rol == "admin-empresa") {
                this.departamento.empresa_id = this.empresa_value = this.datosLogin.empresa_id;
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
            } else if (this.rol == "admin-sucursal") {
                this.departamento.sucursal_id = this.sucursal_value = this.datosLogin.sucursal_id;
                this.filters.AND.push({column:'SUCURSAL_ID', value:this.datosLogin.sucursal_id});
            }
        },

        /**
         * @method getPaginationInfo Método para la paginación
         * @description Método que sirve para la paginación de la tabla
         */
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.loading = true;
        },

        /**
         * @method getClientes Método para clientes
         * @description Método para hacer petición a la API de clientes
         */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            clienteAPi.getClientes(param).then((response) => {
                this.clientes = response.data;
                this.finaliza_parametros();
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method cambiaCliente Este método realiza la petición a la api de empresas.
         * @description Este método realiza la petición a la api de empresas, esta petición sirve para llenar el campo de empresas en la parte de los filtros.
         * @param {int} id id de cliente, sirve para enviar en los paramentros de la api de empresas.
         */
        cambiaCliente(id, cliente = null) {
            if (this.departamento.cliente_id != id) {
                this.empresa_value = null;
                this.sucursal_value = null;
                this.departamento.empresa_id = null;
                this.departamento.sucursal_id = null;
            }

            if(id == null){
                return;
            }

            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.departamento.empresa_id != null) {
                    this.empresa_value = this.departamento.empresa_id;
                    this.finaliza_parametros();
                }
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method cambiaEmpresa Este método realiza la petición a la api de sucursales.
         * @description Este método realiza la petición a la api de sucursales, esta petición sirve para llenar el campo de sucursales.
         * @param {int} id id de empresa, sirve para enviar en los paramentros de la api de sucursales.
         */
        cambiaEmpresa(id, sucursal = null) {
            if (this.departamento.empresa_id != id) {
                this.sucursal_value = null;
                this.departamento.sucursal_id = null;
            }
            if(id == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: id };
            sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
                if (this.departamento.sucursal_id != null) {
                    this.sucursal_value = this.departamento.sucursal_id;
                    this.finaliza_parametros();
                }
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        /**
         * @method abrirModal Método para abrir el modal de departamentos
         * @description Método para abrir el modal de departamentos. Modal para agregar o actualizar un departamento.
         * @param {string} accion La acción puede ser "add" o "update"
         * @param {object} item Objeto que esta en null si la acción es "add"; item tiene los datos de un departamento para que se pinten en el modal.
         */
        async abrirModal(accion, item = null) {
            await this.loadModalData();
            this.accion     = accion;
            await this.resetValues();
            this.isLoadingModal  = true;
            

            switch (accion) {
                case "add":
                    this.modalDias = false;
                    this.modalRegistro = true;
                    break;
                case "update":
                    this.modalDias = false;
                    this.modalRegistro = true;
                    break;
                case "dias":
                    this.modalDias = true;
                    this.modalRegistro = false;
                    this.diasFestivos(item);
                    break;
            }

            if(accion == "add") {
                this.imagen = "/static/modal/departamentoCreate.svg";
                this.tituloModal = "Registrar departamento";
                this.isLoadingModal  = false;
            } else if (accion == "update") {
                this.tituloModal                = "Actualizar departamento";
                this.imagen                     = "/static/modal/departamentoUpdate.svg";
                this.departamento.id            = item.id;
                this.departamento.nombre        = item.nombre;
                this.departamento.encargado     = item.encargado;
                this.departamento.correo        = item.correo;
                this.departamento.descripcion   = item.descripcion;
                this.departamento.cliente_id    = item.cliente_id;
                this.departamento.empresa_id    = item.empresa_id;
                this.departamento.sucursal_id   = item.sucursal_id;
                this.cliente_value              = item.cliente_id;
                this.isLoadingModal  = false;
            } else {
                this.tituloModal           = "Dias Festivos";
            }
            if (this.rol == "root") {
                this.getClientes();
                this.finaliza_parametros();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.finaliza_parametros();
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.finaliza_parametros();
            } else if (this.rol == "admin-sucursal") {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.finaliza_parametros();
            }

            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },

        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValues() para resetear los valores.
         */
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },

        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValues() { 
            this.departamento = {
                id: 0,
                nombre: "",
                encargado: "",
                correo: "",
                descripcion: "",
                sucursal_id: null,
                empresa_id: null,
                cliente_id: null,
            };
            this.dias_festivos = [];
            if(this.rol == "root"){
                this.cliente_value = null;
                this.empresa_value = null;
                this.sucursal_value = null;
                this.empresas = [];
                this.sucursales = [];
            }
            else if(this.rol == "admin"){
                this.empresa_value = null;
                this.sucursal_value = null;
                this.sucursales = []
            }
            else if(this.rol == "admin-empresa"){
                this.sucursal_value = null;
            }
            
            this.nombre = null;
            this.finaliza_carga = false; 
            
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }
            });
        },

        /**
         * @method setFilters Método para el filtrado.
         * @description Este método se ejecuta cuando se le da click al botón "buscar". Se realiza la petición con base a los campos solicitados.
         */
        setFilters() {
            this.filters = {AND:[]};
            
            let nombre = this.nombre;
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let sucursal = this.sucursal_value;
            
            this.loading = true;
            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } 
            else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            }
            else if(this.rol == "admin-sucursal"){
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
                sucursal = this.datosLogin.sucursal_id;
            } 


            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa});
            }  
                
            if(sucursal != null && sucursal != undefined && sucursal != ""){
                this.filters.AND.push({column:'SUCURSAL_ID', value:sucursal})
            }  

            this.paginationData.numberPage = 1;
            this.$apollo.queries.departamentos.refetch();
            this.resetValues();
        },

        /**
         * @method diasFestivos Método para hacer petición a días festivos.
         * @description Método para hacer petición a los días festivos segun sea el departamento.
         * @param {object} data datos del departamento
         */
        diasFestivos(data) {
            this.dias = false;
            this.dias_festivos = [];
            
            departamentoApi.getDepartamento(data.id).then((response) => {   
                response.dias_festivos.sort((a, b) => new Date(a.fecha).getTime() - new Date(b.fecha).getTime());
                let formateado = response.dias_festivos;
                formateado.forEach((element) => {
                    if (element["fecha"] != undefined) {
                        element["fecha_formateada"] = element["fecha"].replace(
                            /^(\d{4})-(\d{2})-(\d{2})$/g,
                            "$3/$2/$1"
                        );
                    }
                    this.dias_festivos.push(element);
                    if (this.dias_festivos.length > 0) {
                    this.dias = true;
                    }
                });
                this.finaliza_carga = true; 
            })
            .catch((error) => {
                this.finaliza_carga = true; 
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.");
            });
            
        },

        /**
         * @method loadModalData Este método se ejecuta cuando se abre el modal de busqueda avanzada.
         * @description Este método se ejecuta cuando se abre el modal de busqueda avanzada. Se realizan las peticiones para llenar los campos que se requieran dependiendo del rol.
         * Se ejecuta como emit del componente tabla.
         */
        async loadModalData() {
            await this.resetValues();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },

        /**
         * @method eliminar Método que elimina una sucursal
         * @description Método que elimina una sucursal
         * @param {int} id Id del departamento a eliminar
         * @param {string} depto Nombre del departamento a eliminar
         */
        eliminar(id, depto) {
            Notify.Alert(
                "¿Estás seguro de eliminar este departamento?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    departamentoApi.deleteDepartamento(id).then((response) => {
                        this.$apollo.queries.departamentos.refetch();
                        this.respuesta_ok("El departamento se ha eliminado satisfactoriamente.");
                    })
                    .catch((error) => {
                        this.resetValue();
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            );
        },

        /**
         * @method finaliza_parametros Método para controlar la carga de los parametros.
         * @description Método para controlar la carga de los parametros.
         */
        finaliza_parametros() {
            this.conteo_parametros = this.conteo_parametros + 1;
            if (this.accion == "add" && this.conteo_parametros >= 2) {
                this.finaliza_carga = true;
            }

            if (
                this.accion == "update" &&
                this.conteo_parametros >= 3 &&
                (this.rol == "root" ||
                this.rol == "admin" ||
                this.rol == "admin-empresa")
            ) {
                this.finaliza_carga = true;
            }
            if (
                this.accion == "update" &&
                this.conteo_parametros >= 1 &&
                this.rol == "admin-sucursal"
            ) {
                this.finaliza_carga = true;
            }

            if(this.accion == "dias"){
                this.finaliza_carga = true;
            }
        },

        /**
         * @method respuesta_ok Método para mostrar un mensaje exitoso
         * @description Método para mostrar un mensaje de opercación exitosa.
         * @param {string} msj Mensaje para mostrar en modal. 
         */
        respuesta_ok(msj) {
            this.listar();
            this.cerrarModal();
            this.resetValues();
            this.dialog = false;
            Notify.Success("Operación exitosa", msj);
        },

        /**
         * @method guardar Este método guarda los datos que se necesitan para el registro de un departamento.
         * @description Este método guarda los datos que se necesitan para un departamento.
         * El método se llama para crear o actulizar un registro para un departamento. En caso de que la petición
         * sea correcta la tabla se actualiza.
         */
        guardar() {
            this.isSaving = true;
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            this.departamento.cliente_id = this.cliente_value;
            this.departamento.empresa_id = this.empresa_value;
            this.departamento.sucursal_id = this.sucursal_value;

            this.isLoading = true;
            if (this.accion === "add") {
                departamentoApi.addDepartamento(this.departamento).then((response) => {
                    this.$apollo.queries.departamentos.refetch();
                    this.respuesta_ok("El nuevo departamento se ha guardado satisfactoriamente.");
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.respuesta_error(error);
                });
            } else if (this.accion === "update") {
                departamentoApi.updateDepartamento(this.departamento).then((response) => {
                    this.$apollo.queries.departamentos.refetch();
                    this.respuesta_ok("El departamento se actualizó satisfactoriamente.");
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.respuesta_error(error);
                });
            }
        },

        /**
         * @method respuesta_error Método para mensaje de errores
         * @description Método para mostrar los errores mediante una notificación.
         * @param {string, object} err El error puede ser de tipo string o de tipo objeto. 
         */
        respuesta_error(err) {
            this.isSaving = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },

        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa, admin-sucursal
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },

        permisosVista(){
            let permisosStorage = JSON.parse(sessionStorage.getItem("permisos"))
            this.permisos.create = permisosStorage.includes("departamentos.store")
            this.permisos.update = permisosStorage.includes("departamentos.update")
            this.permisos.delete = permisosStorage.includes("departamentos.delete")
        }
    },

    /**
     * @description mounted(). Se inician los valores que se requieren al montar los componentes en la vista.
    */
    mounted(){
        this.$apollo.queries.departamentos.skip = false;
        $("tr th:nth-child(1) div:nth-child(1)").css("max-width", "20rem");
        $("tr th:nth-child(1) div:nth-child(1)").css("min-width", "19rem");
        $("tr th:nth-child(1) div:nth-child(1) div").css("max-width", "2rem");
        $("tr th:nth-child(1) div:nth-child(1) div").css("min-width", "1rem");
    },

    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created() {
        var hoy = new Date();
        this.anio_Actual = hoy.getFullYear();
        this.listar();
        this.permisosVista();
    },

    /**
     * @name apollo:departamentos
     * @description GraphQL apollo, query departamentos, realiza la petición a departamentos y los datos se utilizan en el componente tabla. 
     */
    apollo: {
        departamentos: {
            query       : queries.departamentosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.loading =  true;
                return {
                    whereCondition  : this.filters,
                    numberItems     : this.paginationData.numberItems,
                    numberPage      : this.paginationData.numberPage,
                    fieldOrder      : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order           : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.loading    = false;
                this.tableData  = this.departamentos;   
            }
        }
    } 
}
</script>
<style scoped>
    /* Estilos para el loadig de template modal*/
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }

    /* fin estilos para el loadig de template modal*/
    .tituloDia {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 30px;
        color: #004baf;
    }
    .titleModalDiasFestivos {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        color: #1E2245;
    }
    .fechas {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #636997;
        margin-left:12px;
    }

    .descripcion {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: justify;
        color: #828282;
    }
    .divisionDias {
        margin-top: 18px;
        margin-bottom: 12px;
        border-top: solid 1px #c4c4c4;
    }
    .pipeDiasFestivos{
        border: 1px solid #636997;
        background-color: #636997;
        border-radius: 10px;
        height: 30px !important;
        margin-left:0px;
        margin-right: 10px;
    }
    .nombreDia {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #828282;
    }
    .nombreSinDias{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #BDBDBD;
    }
    .v-list-item {
        margin-top: -30px !important;
    }
    /** estilos para le boton cerrar modal dias festivos */
    .v-btn-cerrar .v-btn:hover::before {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
        background-color: transparent !important;
        pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
        background-color: transparent !important;
        pointer-events: none;
    }
    .textoCorreos{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        padding-bottom: 10px;
    }
</style>